.application__language--EN {

	--font-family-text-extra: 'rounded_mplus_1c_extra';
	--font-family-text-medium: 'rounded_mplus_1c_medium';
	--font-family-text: 'rounded_mplus_1c';
	--font-family-games-header: "happy_school";
	--font-family-auth-header: 'blues_smile';
	--font-family-auth-text: "Roboto";

}

.application__language--GR {

	--font-family-text-extra: 'Gilam';
	--font-family-text-medium: 'Gilam';
	--font-family-text: 'Gilam';
	--font-family-games-header: "Gilam";
	--font-family-auth-header: 'Gilam';
	--font-family-auth-text: "Gilam";

}

.application__language--FR {

	--font-family-text-extra: 'BryndanWriteBook';
	--font-family-text-medium: 'BryndanWriteBook';
	--font-family-text: 'BryndanWriteBook';
	--font-family-games-header: "BryndanWriteBook";
	--font-family-auth-header: 'BryndanWriteBook';
	--font-family-auth-text: "BryndanWriteBook";

}

.application__language--DE {

	--font-family-text-extra: 'BryndanWriteBook';
	--font-family-text-medium: 'BryndanWriteBook';
	--font-family-text: 'BryndanWriteBook';
	--font-family-games-header: "BryndanWriteBook";
	--font-family-auth-header: 'BryndanWriteBook';
	--font-family-auth-text: "BryndanWriteBook";

}

.application__language--FI {
	--font-family-text-extra: 'BryndanWriteBook';
	--font-family-text-medium: 'BryndanWriteBook';
	--font-family-text: 'BryndanWriteBook';
	--font-family-games-header: "BryndanWriteBook";
	--font-family-auth-header: 'BryndanWriteBook';
	--font-family-auth-text: "BryndanWriteBook";
}

.application__language--KA {
	--font-family-text-extra: 'BryndanWriteBook';
	--font-family-text-medium: 'BryndanWriteBook';
	--font-family-text: 'BryndanWriteBook';
	--font-family-games-header: "BryndanWriteBook";
	--font-family-auth-header: 'BryndanWriteBook';
	--font-family-auth-text: "BryndanWriteBook";
}