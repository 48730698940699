.shepherd-element {
    transform: translateY(1rem) !important;
    width: 95%;
    border-radius: 1rem;
    padding: 0.5em;

    .shepherd-footer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;

    }
    .shepherd-button {
        font-size: var(--font-size-h2);

    }

    .intro-btn {
        font-size: var(--font-size-p);

        border-radius: 60px;
        color: white;

        // &:hover {
        //     background: initial;
        // }
    }

    .intro-title {
        color: black;
        font-size: var(--font-size-h3);
        margin-bottom: 5px;
    }

    .intro-body {
        color: #A4A4B0;
        font-size: var(--font-size-p) ;
    }

    .back-btn {
        background: linear-gradient(144.09deg, #607c32 13.27%, #A2D848 85.37%) !important;
        font-size: var(--font-size-p) !important;

        :hover {
            background: linear-gradient(144.09deg, #607c32 13.27%, #A2D848 85.37%) !important;

        }
    }

    .skip-btn {
        font-size: var(--font-size-p) ;

    }

    .next-btn {
        background: linear-gradient(144.09deg, #AFE25B 13.27%, #A2D848 85.37%) !important;
        font-size: var(--font-size-p) !important;

        :hover {
            background: linear-gradient(144.09deg, #607c32 13.27%, #A2D848 85.37%) !important;
            
        }
    }
}