/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./app/styles";
@import "~shepherd.js/dist/css/shepherd.css";


@font-face {
	font-family: 'happy_school';
	src: local("happy_school"), url(./assets/fonts/happy_school.ttf) format("truetype");
}

@font-face {
	font-family: 'blues_smile';
	src: local("blues_smile"), url(./assets/fonts/blues_smile.ttf) format("truetype");
}

@font-face {
	font-family: 'rounded_mplus_1c';
	src: local("rounded_mplus_1c"), url(./assets/fonts/MPLUSRounded1c-Regular.ttf) format("truetype");
}

@font-face {
	font-family: 'rounded_mplus_1c_extra';
	src: local("rounded_mplus_1c_extra"), url(./assets/fonts/MPLUSRounded1c-ExtraBold.ttf) format("truetype");
}

@font-face {
	font-family: 'rounded_mplus_1c_medium';
	src: local("rounded_mplus_1c_medium"), url(./assets/fonts/MPLUSRounded1c-Medium.ttf) format("truetype");
}

@font-face {
	font-family: 'Roboto';
	src: url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
	font-weight: 300;
}

@font-face {
	font-family: 'Roboto';
	src: url(./assets/fonts/Roboto-Medium.ttf) format("truetype");
	font-weight: 500;

}

@font-face {
	font-family: 'Roboto';
	src: url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
	font-weight: 700;

}

@font-face {
	font-family: 'Gilam';
	src: url(./assets/fonts/GilamBold.woff2) format('woff2');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gilam';
	src: url(./assets/fonts/GilamLight.woff2) format('woff2');
	font-weight: lighter;
	font-style: normal;
}

@font-face {
	font-family: 'Gilam';
	src: url(./assets/fonts/GilamRegular.woff2) format('woff2');
	font-weight: normal;
	font-style: normal;
}

// FONT TESTING

@font-face {
	font-family: 'AthenaVKF';
	src: url(./assets/fonts/testing/AthenaVKF.otf) format("truetype");
}

@font-face {
	font-family: 'BryndanWriteBook';
	src: url(./assets/fonts/testing/BryndanWriteBook-nGPM.ttf) format("truetype");
}

@font-face {
	font-family: 'Hyatheus';
	src: url(./assets/fonts/testing/Hyatheus-v8BO.otf) format("truetype");
}

@font-face {
	font-family: 'remissis';
	src: url(./assets/fonts/testing/remissis.sb-regular.otf) format("truetype");
}

@font-face {
	font-family: 'Slimamifbold';
	src: url(./assets/fonts/testing/Slimamifbold-vLJM.ttf) format("truetype");
}

@font-face {
	font-family: 'Starzy3';
	src: url(./assets/fonts/testing/Starzy3-jqw0.ttf) format("truetype");
}

@font-face {
	font-family: 'Mansalva';
	src: url(./assets/fonts/testing/Mansalva-Regular.ttf) format("truetype");
}


ion-header ion-title {
	display: flex;
	justify-content: center;
	text-align: center;
}


:root {
	background: $root__background;
	font-family: 'Gilam', sans-serif;
}

ion-modal {
	--height: 80%;
	--border-radius: 16px;
	padding: 1em;
}

ion-modal::part(backdrop) {
	background: rgba(88, 88, 88, 0.7);
	opacity: 0;
}

ion-modal ion-toolbar {
	--background: rgb(14 116 144);
	--color: white;
}

ion-modal.questions-modal {
	--height: 100%;
}

ion-modal.welcome-modal {
	--height: 13rem;
}

ion-modal.confirm-modal {
	--height: 13rem;


}

ion-modal.reset-pw-modal {
	display: flex;
	flex-direction: center;
	justify-content: center;
}

.permission-alert * {
	font-family: sans-serif;
}


.custom-checkbox {
	--background-checked: #AFE25B;
	--border-color: #AFE25B;
	--border-color-checked: #AFE25B;
}

.toast-alert {

	--background: #AFE25B;
	--color: black;
	--button-color: #AFE25B;
	--border-radius: 20px;

	text-align: center;


}

div,
span,
p {
	font-size: var(--font-size-p);
}

h5 {
	font-size: var(--font-size-h5);
}

h2 {
	font-size: var(--font-size-h2);

}

h3 {
	font-size: var(--font-size-h3);

}

.confirm-modal {
	padding: 0 2em;

	.ion-page {
		background-color: rgb(249, 249, 249);
	}

}



.parental-gate-modal {
	padding: 0 2em;
	.ion-page {
		background-color: rgb(249, 249, 249);
	}
}

.pointer {
	cursor: pointer;
}

@media screen and (orientation:landscape) {}

@media screen and (orientation:portrait) {}