$root__background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #F1F5F9;




$toolbar--background-primary: linear-gradient(144.09deg, #AFE25B 13.27%, #A2D848 85.37%);
$toolbar--background-secondary: linear-gradient(124.38deg, #6575FF 13.97%, #5C4EF9 83.61%);
$toolbar__header--title-letter-color: #fff;



$menu-item--font-color: #42437D;
$menu-item--background-color: #fff;

$progress-ring__container--font-color: #42437D;
$progress-ring__container--background-color: #fff;
$progress-ring__comment--font-color: #A4A4B0;
$progress-bar--background-color: #7FCF2F;
$progress-wrapper--font-color: #fff;
$progress-wrapper--background-color: #E2FFC6;
$menu-item__textbox__p--color: #8586B9;
$resume-game-cta__text-box-children--color: #fff;
$resume-game-cta__container--background: linear-gradient(329.79deg, #0052D4 -31.21%, #4364F7 18.94%, #6FB1FC 98.47%);
$user-stats__container--background: #fff;
$user-stats__container--color: #82CA3A;
$main-menu__content--height: 90%;
$main-menu__content--width: 100%;
$menu-items__container--height: 50%;
$menu-items__container--width: 96%;

$auth-page__background: linear-gradient(179.9deg, #3E76E2 1.57%, #76A2FF 11.16%, #6FBBF4 20.99%, #ABDDEA 37.38%, #E4FCFF 59.84%, #FFFFFF 91.2%);
$auth-page__heading--color: #fff;
$auth-form__checkbox--border-color: #D5D5D5;
$auth-form__remember-me--color: #657072;
$auth-form__forgot-password--color: #657072;
$auth-form__notice--color: red;