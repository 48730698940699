// DIMENSIONS VARIABLES

// @import "../../styles/dimensions.scss";
// @import "../../styles/colors.scss";


$side-margin: 4%;


$toolbar--radius: 32.0513px 32.0513px 38px 38px;
$toolbar--width: 100%;
$toolbar--height: 30%;
$toolbar__header--margin: 1.2rem;
$toolbar__header--title-letter-spacing: 1px;
$toolbar__circle-a--width: 73%;
$toolbar__circle-a--top: -11%;
$toolbar__circle-a--left: -23%;
$toolbar__circle-b--width: 43%;
$toolbar__circle-b--top: 7%;
$toolbar__circle-b--left: 71%;

// PROGRESS RING COMPONENT
$progress-ring__container--height: 20%;
$progress-ring__container--width: calc(96% - 16px);
$progress-ring__container--padding: 10px;
$progress-ring__container--margin-y: 10px;
$progress-ring__container--border-radius: 10px;
$progress-ring__text-box--width: 60%;
$progress-ring__graphic-container--width: 40%;
$progress-ring__graphic-container--height: 120px;
$progress-ring__graphic--width: 120px;
$progress-ring__indicator-container--width: 120px;
$progress-ring__heading--font-size: 32px;
$progress-ring__comment--font-size: 20px;
$progress-ring__percentage-indicator--font-size: 28px;
$progress-ring__level-indicator--font-size: 16px;
$progress-ring__heading--font-weight: 900;
$progress-ring__heading--padding-left: 10px;
$progress-ring__comment--line-height: 20px;
$progress-ring__comment--margin: 10px;

$progress-wrapper--border-radius: 20px;
$progress-bar--border-radius: 20px;
$progress-bar--height: 20px;
$progress-bar--background-size: 1.2em 1.2em;

$progress-bar--min-width: 1%;
// $progress-percentage--width:  100%;
// $progress-percentage--height:  20px;

$countdown__timer-icon--height: 16px;
$countdown__timer-icon--width: 16px;
$countdown__timer-icon--margin-right: 2px;
$countdown--font-size: 10px;
$countdown--font-weight: 900;


$menu-item__container--width: 159px;
$menu-item__container--height: 149px;
$menu-item__container--border-radius: 10px;
$menu-item__container--margin: 8px;


$menu-item__icon--margin: 12px;
$menu-item__icon--width: 56px;
$menu-item__icon--height: 56px;
$menu-item__icon--z-index: 1;

$menu-item__arrow--margin: 10px;
$menu-item__arrow--width: 28px;
$menu-item__arrow--height: 28px;

$menu-item__textbox--width: 100%;
$menu-item__textbox--margin: 14px;
$menu-item__textbox--z-index: 1;

$menu-item__textbox__p--font-size: 10px;
$menu-item__textbox__p--font-weight: 100;

$menu-item__circle--top: -97px;
$menu-item__circle--left: 48px;

$resume-game-cta__decoration-shape--position: relative;
$resume-game-cta__decoration-shape--width: 131px;
$resume-game-cta__decoration-shape--height: 140px;
$resume-game-cta__decoration-shape--top: 41px;
$resume-game-cta__decoration-shape--left: -32px;
$resume-game-cta__decoration-shape--z-index: 2;

$resume-game-cta__text-box--width: 100%;
$resume-game-cta__text-box-children--width: 12rem;
$resume-game-cta__text-box-children--padding: 0;
$resume-game-cta__text-box-children--margin: 0;
$resume-game-cta__text-box-children--margin-left: 1rem;
$resume-game-cta__container--border-radius: 10px;
$resume-game-cta__container--width: calc(96% - 16px);
$resume-game-cta__container--margin: 20px auto;
$resume-game-cta__container--padding: 10px 20px;
$resume-game-cta__container--height: 12%;

$resume-game-cta__resume-icon--width: 56px;
$resume-game-cta__resume-icon--height: 56px;

$user-stats__stats-row--margin-bottom: 4px;
$user-stats__stats--width: 100%;
$user-stats__profile-pic--padding-right: 10px;

$user-stats__container--width: calc(96% - 16px);
$user-stats__container--margin: 10px auto;
$user-stats__container--padding: 10px;
$user-stats__container--padding-top: 4px;
$user-stats__container--border-radius: 10px;

$auth-page__container--height: 100%;
$auth-page__logo-container--min-height: 50%;
$auth-page__heading--top: 0;
$auth-page__heading--font-size: 32px;

$auth-form__input--margin-top: 20px;
$auth-form__input--padding: 15px 25px;
$auth-form--top: 0%;
$auth-form__input--font-weight: 600;
$auth-form__input--width: 70%;
$auth-form__input--border-radius: 20px;
$auth-form__links--margin: 10px 0;
$auth-form__links--padding: 5px 0px 25px 0px;
$auth-form__links--width: 70%;
$auth-form__links--font-size: 14px;
$auth-form__checkbox--size: 16px;
$auth-form__checkbox--border-radius: 4px;
$auth-form__checkbox--border-width: 2px;
$auth-form__checkbox--margin-right: 4px;
$auth-form__remember-me--font-weight: 300;
$auth-form__forgot-password--font-weight: 300;
$app-rounded-button--width: 70%;
$app-rounded-button--margin-top: 50px;
$auth-form__notice--padding-top: 5px;
$auth-form__notice--min-height: 25px;
$auth-form__notice--font-weight: 400;


$auth-page__cloud-a--left: -57px;
$auth-page__cloud-b--right: -87px;
$auth-page__cloud-b--top: 338px;
$auth-page__vector-a--opacity: 0.1;
$auth-page__vector-a--top: 0;
$auth-page__vector-a--right: -56px;
$auth-page__vector-b--opacity: 0.5;
$auth-page__vector-b--top: 185px;
$auth-page__vector-b--left: 1px;
$auth-page__vector-c--top: 45%;
$auth-page__vector-c--height: 300px;
$auth-page__vector-c--width: 100%;
$auth-page__vector-c--opacity: 0.4;



$breakpoint-tablet: 768px;
$breakpoint-web: 1440px;

@media (min-width: $breakpoint-tablet) {
  $side-margin: 13%;
}

:root {
  --responsive-padding: 5%;
  --responsive-padding-vertical: 5%;
  --user-stats-padding-v: 10px;
  --user-stats-padding-h: 10px;
  --user-stats-padding-l: 15px;
  --user-stats-font-size: 14px;
  --user-stats-text-margin-b: 4px --font-size-h5: 16px;
  --font-size-h3: 20px;
  --font-size-h2: 20px;
  --font-size-h1: 32px;
  --font-size-p: 12px;
  --toolbar-icon-size: 35px;
  --auth-width: 70%;
  --profile-pic-size: 60px;
  --game-thumbnail-size: 64px;
  --game-list-item-padding: 12px;
  --resume-game-padding-v: 20px;
  --resume-game-padding-h: 10px;

  --main-menu-container-height: calc(100% - calc(2 * var(--resume-game-padding-v)) - 55px - 38px)
}

@media (min-width: $breakpoint-tablet) {
  :root {
    --responsive-padding: 10%;
    --profile-pic-size: 90px;
    --user-stats-font-size: 16px;
    --game-thumbnail-size: 72px;
    --user-stats-text-margin-b: 6px --font-size-h5: 24px;
    --font-size-h3: 28px;
    --font-size-h2: 32px;
    --font-size-h1: 36px;
    --toolbar-icon-size: 52px;
    --resume-game-padding-v: 40px;
    --resume-game-padding-h: 20px;
    --font-size-p: 16px;
    --game-list-item-padding: 20px;
    --main-menu-container-height: calc(100% - calc(2 * var(--resume-game-padding-v)) - 55px - 38px)
  }

  @media (min-width: $breakpoint-tablet) and (orientation:landscape) {
    :root {
      --auth-width: 400px;
      --resume-game-padding-v: 20px;
      --resume-game-padding-h: 10px;
    }
  }

  @media (min-width: $breakpoint-web) {
    :root {
      --responsive-padding: calc((100vw - 1200px) /2);
      --responsive-padding-vertical: 2%;
      --user-stats-padding-v: 10px;
      --user-stats-padding-h: 10px;
      --user-stats-padding-l: 30px;
      --profile-pic-size: 120px;
      --user-stats-font-size: 24px;
      --user-stats-text-margin-b: 8px;
      --font-size-h5: 32px;
      --font-size-h2: 36px;
      --font-size-h3: 32px;
      --font-size-h1: 38px;
      --toolbar-icon-size: 64px;
      --resume-game-padding-v: 20px;
      --resume-game-padding-h: 20px;
      --font-size-p: 24px;
      --game-thumbnail-size: 75px;
      --game-list-item-padding: 32px;
      --main-menu-container-height: calc(100% - calc(2 * var(--resume-game-padding-v)) - 55px - 38px)
    }
  }
}