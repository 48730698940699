.flex-half {
    flex: 1;
    flex-basis: 45%;
}

div {
	font-family: var(--font-family-games-header);

	& p {
		font-family: var(--font-family-text);

	}
}
